import React, { ReactNode } from 'react';
import { TreeCacheProvider } from './TreeCacheProvider';
import { RuleOptionsProvider } from './RuleOptionsProvider';

interface PersonalizationRulesProviderProps {
  children: ReactNode;
}

export function PersonalizationRulesProvider({ children }: PersonalizationRulesProviderProps) {
  return (
    <TreeCacheProvider>
      <RuleOptionsProvider>{children}</RuleOptionsProvider>
    </TreeCacheProvider>
  );
}
